import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")

  return (_openBlock(), _createBlock(_component_KTModalCard, {
    title: "Create App Modal Example",
    description: "Click on the below buttons to launch <br/>create app modal example.",
    image: "media/illustrations/work.png",
    "button-text": "Create App",
    "modal-id": "kt_modal_create_app"
  }))
}